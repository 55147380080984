// Includes all the API endpoints.
// For responses that returns an array,
// use /{endpoint}/all
// for responses that returns a single resource,
// use /{endpoint}
export enum APIEndpointEnum {
  GEOBLOCK = "/check-restricted",

  ANALYTICS = "/analytics",
  ACCOUNT = "/account",
  MMP = "/mmp",
  BALANCE_HISTORY = "/balance-history",
  PRICE_HISTORICAL = "/index-history",
  INDEX = "/index",
  TIME = "/time",

  OPTIONS_CHAIN = "/options-chain",
  MARKETS = "/markets",
  ORDERBOOK = "/orderbook",

  ORDERS = "/orders", // Get orders
  ORDER_HISTORY = "/order-history", // Get orders

  TRADES = "/trade-history", // Trade history
  INSTRUMENT_TRADES = "/instrument-trades",
  OPTIONS_HISTORY = "/options-history", // Options history

  TRANSACTION_HISTORY = "/transaction-history",

  PORTFOLIO = "/portfolio",
  PORTFOLIO_MARGIN = "/account/portfolio-margin",
  STATISTICS = "/statistics",
  MARKET_SUMMARY = "/market-summary",
  INSTRUMENT = "/instrument",
  MARGIN = "/margin",
  NOTIFICATIONS = "/notifications",

  FUNDING = "/funding",
  FUNDING_HISTORY = "/funding-history",
  SETTLEMENT_HISTORY = "/settlement-history",

  LEADERBOARD = "/leaderboard",
  CAMPAIGN_LEADERBOARD = "/leaderboard/campaign",

  REFERRAL_HISTORY = "/referral-history",
  REFERRAL_REWARD_HISTORY = "/referral-reward-history",
  REFERRAL_STATISTICS = "/referral-statistics",
  CHECK_REFERRAL = "/check-referral",
  CLAIM_REWARDS = "/claim-referral-rewards",
  EMAIL_PREFERENCES = "/account/email-preferences",
  EMAIL_PREFERENCE = "/account/email-preference",
  EMAIL_VERIFIED = "/account/email-verified",
  EMAIL_ADDRESS = "/account/email-address",
  TRADE_HISTORY_CSV = "/account/trades/csv",
  TX_HISTORY_CSV = "/account/transactions/csv",
  ACCOUNT_STATISTICS = "/account/stats",

  RFQS = "rfqs",
  QUOTES = "quotes",
  ACCUMULATED_FUNDINGS = "/account/accumulated-fundings",

  MAX_LEVERAGES = "/max-leverages",
  ACCOUNT_LEVERAGE = "/account/leverage",
  YIELD_VAULT = "/yield-vault",

  FARM_BOOST = "/farm-boost",
  EMISSIONS = "/emissions",

  L1_CAPACITY = "/l1/capacity",
  WITHDRAWAL_FEES = "/withdraw/fees",

  PROOF_DATA = "/proof-data",

  STRATEGIES = "/strategies",
  STRATEGIES_ACCOUNT = "/strategies/account",
  STRATEGY_POSITIONS = "/strategy/{strategy_address}/positions",
  STRATEGY_TRADE_HISTORY = "/strategy/{strategy_address}/trade-history",
  STRATEGY_PORTFOLIO = "/strategy/{strategy_address}/portfolio",
  STRATEGY_BALANCE_HISTORY = "/strategy/{strategy_address}/balance-history",
  STRATEGY_PNL_HISTORY = "/strategy/{strategy_address}/pnl-history",
  STRATEGY_TX_HISTORY = "/strategy/{strategy_address}/transaction-history",
  STRATEGY_ACCOUNT_HISTORY = "/strategies/account-history",

  PROOFS_DATA = "/proofs-data",

  SOCKET_FEES = "/socket-fees",
}

export enum PageEndpointEnum {
  TRADING = "/",
  REF_LINK = "/r", // A redirect page for when a user lands on the app via a reflink
  PORTFOLIO = "/portfolio",
  LEADERBOARD = "/leaderboard",
  CAMPAIGNS = "/campaigns",
  REFERRALS = "/referrals",
  AEVO = "/aevo",
  AIRDROPS = "/airdrops",
  ACCOUNT = "/account",
  HISTORICAL = "/historical",
  SETTINGS = "/settings",
  SETTLEMENT_HISTORY = "/settlement-history",
  STRATEGIES = "/strategies",
  STRATEGY = "/strategy",
  RFQ = "/rfq",
  EARN = "/earn",
  PWA = "/pwa",
  TERMS = "/terms",
}

export enum ExternalEndpointEnum {
  HISTORICAL_PRICES = "",
}
